<template>
  <div v-if="showBlock">
    <stand-out-block v-if="showSuccessState" class="events-newsletter-signup-success">
      <div class="heading-4">Thanks for signing up!</div>
      <div class="text-lg">
        Be sure to add “<a href="mailto:contact@mychinaroots.com" class="link">contact@mychinaroots.com</a>” to your
        email contacts or safe-senders list.
      </div>
    </stand-out-block>
    <stand-out-block class="events-newsletter-signup" v-else>
      <div class="heading-4">Get Notified About Upcoming Events</div>
      <div class="text-lg text-lg-mobile">Stay updated on our latest workshops and exclusive events.</div>
      <div class="form">
        <div class="input-group">
          <input
            v-model="email"
            name="email"
            type="email"
            class="input"
            placeholder="Enter your email"
            :class="{error: emailError}"
            @input="onInput"
            @keyup.enter="submit"
          />
          <div class="input-error" v-if="emailError">{{ emailError }}</div>
        </div>
        <mcr-button @click="submit" :loading="loading">Sign Me Up</mcr-button>
      </div>
      <div class="text-md error" v-if="error">{{ error }}</div>
    </stand-out-block>
  </div>
</template>

<script>
import McrButton from '@common/elements/buttons/mcrButton';
import StandOutBlock from '@common/elements/layouts/StandOutBlock';
import {isEmailValid} from '@common/utils/utils';
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      showBlock: false,
      email: '',
      error: '',
      emailError: '',
      loading: false,
      showSuccessState: false,
      eventsWorkshopsPublicListId: '14e739eb-b1a2-4099-a8ad-d5ece7952023',
    };
  },
  created() {
    if (!this.userIsLoggedInState || this.userIsSharedState) {
      this.showBlock = true;
    }
    if (this.userIsLoggedInState && !this.userIsSharedState) {
      this.email = this.userEmailState;
      this.loadEmailSettings();
    }
  },
  computed: {
    ...mapGetters(['userIsLoggedInState', 'userIsSharedState', 'userEmailState']),
  },
  methods: {
    submit() {
      if (!isEmailValid(this.email)) {
        this.emailError = `"${this.email}" is not a valid email.`;
        return;
      }
      if (this.loading) {
        return;
      }
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('publicaccountid', '88931ce4-50c7-4882-8973-710669a4c8b1');
        formData.append('activationTemplate', 'Blog Newsletter Signup Activation');
        // Events & Workshops
        formData.append('publiclistid', this.eventsWorkshopsPublicListId);

        fetch('https://api.elasticemail.com/contact/add?version=2', {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.success) {
              this.showSuccessState = true;
            } else {
              this.handleError(data);
            }
          })
          .catch(error => {
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      } catch (error) {
        this.loading = false;
        this.handleError(error);
      }
    },
    handleError(error) {
      console.error(new Error('Error during Events Newsletter Signup: ' + error));
      this.error = 'An error occurred. Please try again later.';
    },
    onInput() {
      this.emailError = '';
    },
    loadEmailSettings() {
      this.network.member.getEmailSettings().then(res => {
        this.showBlock = !res.some(
          item => item && item.list_public_id === this.eventsWorkshopsPublicListId && item.value
        );
      });
    },
  },
  name: 'EventsNewsletterSignup',
  components: {StandOutBlock, McrButton},
};
</script>

<style scoped lang="scss">
.events-newsletter-signup {
  padding: 40px 24px;
  text-align: center;
  .text-lg {
    color: $neutral-600;
    margin: 12px 0 32px;
  }
  .form {
    display: flex;
    column-gap: 10px;
    align-items: flex-start;
    .input-group {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      input {
        flex-grow: 1;
      }
    }
  }
  .input-error {
    text-align: start;
  }

  @media only screen and (max-width: $breakpoint-mobile) {
    padding: 32px 16px;
    .text-lg {
      margin: 12px 0 24px;
    }
    .form {
      flex-direction: column;
      align-items: stretch;
      .mcr-button {
        margin-top: 16px;
      }
    }
  }
}

.events-newsletter-signup-success {
  padding: 64px 24px;
  text-align: center;
  .text-lg {
    margin-top: 16px;
  }
}

.events-newsletter-signup,
.events-newsletter-signup-success {
  max-width: 560px;
  margin-top: 35px;
}
</style>
