<template>
  <div class="wiki-header">
    <div class="breadcrumbs">
      <span class="step">
        <router-link :to="{name: 'wiki-index'}">My China Roots Wiki</router-link>
      </span>
      <span v-for="step in steps" class="step">
        <router-link :to="step.route" v-if="step.route">{{ step.label }}</router-link>
        <span v-else>{{ step.label }}</span>
      </span>
    </div>
    <div class="search">
      <icon-input
        ref="search-input"
        class="search-control"
        input-class="input"
        type="search"
        placeholder="Search all articles..."
        @keyup.enter="search"
        @input="setKeyword"
        @focus="inputFocus"
      >
        <search-icon class="search-icon" :size="26" />
      </icon-input>
    </div>
  </div>
</template>

<script>
import IconInput from '@common/elements/inputs/IconInput';
import consts from '@common/utils/consts';
import SearchIcon from 'vue-material-design-icons/Magnify';

export default {
  props: {
    steps: Array,
  },
  data() {
    return {
      keyword: '',
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.tablet;
    },
  },
  methods: {
    inputFocus() {
      if (this.isMobile) {
        this.$scrollTo('.search', {offset: -consts.MAIN_MENU_HEIGHT_MOBILE, cancelable: false});
      }
    },
    search() {
      this.$router.push({name: 'wiki-search', query: {keyword: this.keyword}});
      this.$refs['search-input'].$refs.input.blur();
    },
    setKeyword(event) {
      this.keyword = event.target.value;
    },
  },
  components: {SearchIcon, IconInput},
  name: 'WikiHeader',
};
</script>

<style lang="scss" scoped>
.wiki-header {
  background-color: $background-alternate-color;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;

  .step:not(:last-child)::after {
    content: '\203A';
    color: $supplemental-text-color;
    font-size: 1.18em;
    opacity: 0.8;
    margin: 0 5px;
  }

  .search {
    width: 500px;
  }

  @media only screen and (max-width: $breakpoint-tablet) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 10px;

    .search {
      margin-top: 10px;
      width: 100%;
    }
  }
}
</style>
